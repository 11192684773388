/*#root {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: block;
  position: relative;
}
*/
body {
  padding: 0;
  margin: 0;
  /*background-color: rgba(16, 32, 192, 0.3);
  */
  font-family: 'Atlas Grotesk Regular';
  background-color: #fff;
  /*width: 4000px;
  height: 4000px;*/
  font-size: 14px;
}

#root > * {
	overflow: hidden;
}

.App {
  text-align: center;
  image-rendering: smooth;
}

@font-face {
    font-family: 'Atlas Grotesk Medium';
    src: url('./fonts/AtlasGroteskWeb-Medium.woff2') format('woff2'),
        url('./fonts/AtlasGroteskWeb-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Atlas Grotesk Regular';
    src: url('./fonts/AtlasGroteskWeb-Regular.woff2') format('woff2'),
        url('./fonts/AtlasGroteskWeb-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap; 
}

.font-bold {
  font-family: 'Atlas Grotesk Medium';
}

.perspective {
  perspective-origin: 50% 50%;
  /* transform-style: preserve-3d; */
  perspective: 800px;
  position: relative;
  overflow: hidden;
}

svg {
  position: absolute;
  /* transform-origin: center center -800px;
  transform: rotateX(15deg) rotateY(10deg) rotateZ(-15deg)
    translate3d(40px, 50px, -150px); */
} 

a.button {
  background-color: #6A4AFC;
  border-radius: 4px;
  border: none;
  color: #fff;
  outline: 0;
  padding: 14px 22px;
  transition: .3s all;
  user-select: none;
  text-decoration: none;
}

a.button:hover{
    cursor: pointer;
    opacity: 1
}

.slidecontainer {
  width: 100%;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
  border-radius: 5px;
  background: transparent;
  outline: none;
  top: -2px;
  position: absolute;
  -webkit-transition: .2s;
  transition: opacity .2s;
  z-index: 2;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #6A4AFC;
  cursor: pointer;
  z-index: 2
}

.slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #6A4AFC;
  cursor: pointer;
  z-index: 2
}



.login {
  position: fixed;
  display: block;
  top: -76px;
  right: 0;
  left: 0;
  height: calc(100vh + 76px);
  width: 100vw;
  z-index: 3;
}

.login-logo-container {
  width: 446px;
  max-width: 94vw;
  margin: 0 auto;
}

.login-container {
  min-height: calc(100% + 46px);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  pointer-events: none;
  margin: 0 auto;
  max-width: 446px;
}

.login-container-inner {
  border-radius: 8px;
  background-color: #fff;
  width: 446px;
  max-width: 94vw;
  overflow: hidden;
  position: relative;
  pointer-events: all;
  box-shadow: rgba(0,0,0,7%) 0 2px 24px;
  margin: 0 auto;
}    
  

.login-footer {
  border-top: 1px solid #e6e6e6;
  background-color: #fff;
  padding: 16px;
  bottom: 0;
  width: 446px;
  max-width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}

.distribute {
  display: flex;
  align-items: flex-start;
}

.distribute-inline { display: inline-flex }
.distribute-vertical { flex-direction: column }
.distribute-horizontal { flex-direction: row }
.distribute-start { align-items: flex-start }
.distribute-center { 
    -ms-flex-align: center;
    align-items: center; justify-content: center; 
}
.distribute-end { align-items: flex-end; justify-content: flex-end; }
.distribute-between {align-items: center; justify-content: space-between;}


button.button {
  background-color: #6a4afc;
  border-radius: 4px;
  border: none;
  color: #fff;
  outline: none;
  padding: 10px 22px;
  transition: 0.3s all;
  user-select: none;
  box-sizing: border-box;
}

.button:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

.button:hover {
    cursor: pointer;
    background-color: #6f37c6;
  }


.full-width {
  width: 100%
}

button.link {
  background-color: transparent;
  border: transparent;
  padding: 2px 0;
  color: #6a4afc;
  border-radius: 0;
  border-bottom: 2px solid #6a4afc;
  outline: none;
}

.link:hover {
    /*border: none;*/
    background-color: transparent;
    color: #6f37c6;
    border-bottom: 2px solid #6f37c6;
    outline: none;
    cursor: pointer;
}

.error {
  color: red;
  padding-top: 8px;
}




#load {
  width: 28px;
  animation: loading 1.5s linear infinite;
}

#load circle { 
    stroke-dashoffset: 0;
    stroke-dasharray: 300;
    stroke-width: 10;
    stroke-miterlimit: 10;
    stroke-linecap: round;
    animation: loading-circle 1.5s linear infinite;
    fill: transparent;
  }
 

@keyframes loading {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes loading-circle {
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: -600;
  }
}



.selected {
  height: 64px;
    width: 64px;
    border-radius: 4px;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    background-position: 50%;
    background-size: cover;
    content: "";
    background-color:rgba(0, 0, 0, 0.1);
}

.selected:before {
    background-color: #6a4afc;
    content: "";
    position: absolute;
    width: calc(100% - 32px);
    height: calc(100% - 32px);
    border-radius: 50em;
    top: calc(50% - 16px);
    left: calc(50% - 16px);
    z-index: 1;
}

.overlay:before {
    display: block;
    content: "";
    width: 8px;
    height: 14px;
    box-shadow: 2px 2px #fff;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    left: calc(50% - 4px);
    top: calc(50% - 11px);
    z-index: 1;
}


.dot {
    background-color: #6A4AFC;
    border: 4px solid #fff;
    height: 20px;
    width: 20px;
    border-radius: 50em;
    z-index: 3;
    position: absolute;
    user-select: none;
    transition: none;
    transition: background-color 0.3s;
}

.dot:hover{
      background-color: black;
      cursor: grab;
}  

.poly {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  border: 2px solid #fff;
  background: rgba(106, 74, 252, 0.7);  
  z-index: 0;
}



  .switch {
    position: relative;
    display: block;
    padding-bottom: 16px;
  }

  .switch input {
    opacity: 0;
    position: absolute;
  }

  .switch input:checked + label:before {
    background-color: rgb(106, 74, 252);
    border: 1px solid rgb(106, 74, 252);
  }

  .switch input:checked + label:after {
    background-color: #fff;
    border: 1px solid rgb(106, 74, 252);
    transform: translateX(calc(12px * 2));
  }
  

  .switch label {
    display: flex;
    align-items: center;
    padding-left: 56px;
    height: 24px;
    line-height: 1;
    user-select: none;
  }

  .switch label:before {
      content: "";
        position: absolute;
        left: 0;
        display: inline-block;
        cursor: pointer;
        width: 48px;
        height: 24px;
        background-color: #d3d3d3;
        border-radius: 50em;
    }
  .switch label:after {
    content: "";
      width: 16px;
      height: 16px;
      background-color: #777;
      display: block;
      position: absolute;
      top: 4px;
      left: 4px;
      border-radius: 50%;
      transition: transform 0.2s;
  } 

